<template>
  <div class="content">
    <div class="topBanner" @click="topBanner">
      <img
        src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-07-30/19/yuelvhuiyjUhojHxgC1596109370.png"
        alt=""
      >
    </div>
    <div class="helpContent">
      <div class="helpTitle">帮助中心</div>
      <div
        v-for="(item,index) in helpInfo"
        :key="index"
        class="helpTitleWrap1"
        :class="index/2===0 ? 'helpTitleWrap' : ''"
      >
        <div class="helpTitleOne">
          <img class="helpTitleOneIcon" :src="item.icon" alt="">
          <p class="">{{ item.title }}</p>
        </div>
        <div class="helpTitleTwo">
          <div
            v-for="(items,cIndex) in item.content"
            :key="cIndex"
            class="helpTitleTwoChiled"
            @click="lookHelpDetails(items)"
          >
            <p :class="(cIndex === 0 || cIndex === 1)?'p1':''">{{ items.title }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getXJHelpList } from '@/services/comeOn.js'

export default {
  name: 'ComeOnHelp',
  data() {
    return {
      helpInfo: [],
      courseTopImg: '',
      courseBottomImg: ''
    }
  },
  created() {
    this.getHelpList()
  },
  mounted() {

  },
  methods: {
    getHelpList() {
      getXJHelpList().then(res => {
        this.helpInfo = res.data.helpList
        this.courseTopImg = res.data.courseTopImg
        this.courseBottomImg = res.data.courseBottomImg
        this.courseVideo = res.data.courseVideo
        this.courseVideoCover = res.data.courseVideoCover
      })
    },
    lookHelpDetails(items) {
      this.$router.push({
        path: '/UsageMethod',
        query: {
          id: items.id
        }
      })
    },
    topBanner() {
      this.$router.push({
        path: '/privileges',
        query: {
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
html, body, #app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #F8F8F8;
}

.content {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #F8F8F8;
}

.topBanner {
  width: 100%;
  background: url("../../../assets/images/comeOnHelpBanner.png") no-repeat;
  background-size: 100% 100%;
  padding: 10px;
}

.helpContent {
  width: 100%;
  background-color: #f8f8f8;
  margin-top: 10px;
}

.helpTitle {
  padding: 17px 22px;
  font-size: 17px;
  color: #333;
  font-weight: 500;
  text-align: left;
  background-color: #fff;
}

.helpTitleWrap {
  border-top: 1px solid #eeeeee;
}

.helpTitleWrap1 {
  display: flex;
  margin-bottom: 5px;
  background-color: #fff;
}

.helpTitleOne {
  width: 25%;
  padding: 15px;
  text-align: center;
  border-right: 1px solid #eeeeee;
}

.helpTitleOneIcon {
  width: 25px;
  height: 25px;
  margin-bottom: 8px;
}

.helpTitleTwo {
  width: 75%;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
}

.helpTitleTwoChiled {
  width: 50%;
  text-align: center;
  font-size: 14px;
  line-height: 49px;
}

.helpTitleTwoChiled {
  border-right: 1px solid #eeeeee;
}

.p1 {
  border-bottom: 1px solid #eeeeee;
}
</style>
